import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import userReducer  from '~modules/User/reducers';
import applicationsReducer  from '~modules/Applications/reducers';

const defaultReducers = {
  userReducer
};

export const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ...defaultReducers,
  applicationsReducer
});

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const makeStore = (history) => createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);
