import { FetchUserProfileRoutine, UpdateUserProfileRoutine} from '../redux-routines';

const initialState = {
  user: {},
  isFetching: false,
  error: null,
};

const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case FetchUserProfileRoutine.SUCCESS:
    case UpdateUserProfileRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: null
      };
    case FetchUserProfileRoutine.REQUEST:
    case UpdateUserProfileRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchUserProfileRoutine.FAILURE:
    case UpdateUserProfileRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};


export default userProfile;
