import React, { useEffect, useState } from 'react';

const WithRuntimeConfig = (props: { children: typeof React.Children}) => {
  const { children } = props;
  const [runtimeLoaded, setRuntimeLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      const data = await fetch('/runtimeConfigStatic.js');
      const body = await data.text();
      // @ts-ignore
      window.__runtimeConfigStatic = JSON.parse(body);
      setRuntimeLoaded(true);
    })();
  }, []);

  if(!runtimeLoaded) {
    return null;
  }
  return children;
};

export default WithRuntimeConfig;
