export const NAVIGATION_CONSTANTS = Object.freeze({
  ROOT: '/',
  LOGIN_ROUTE: '/login',
  OIDC_CALLBACK_ROUTE: '/callback',
  LOGOUT_CALLBACK_ROUTE: '/logoutcallback',
});

export const NotificationTypes = Object.freeze({
  DEFAULT: 'default',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
});

const { protocol, host } = window.location;
export const hostBaseUrl = `${protocol}//${host}`;
export const getConfig = () => ({
  idpServerUrl: window.__runtimeConfigStatic?.authentication_server_base_url,
  hostBaseUrl,
  clientId: window.__runtimeConfigStatic?.client_id,
});