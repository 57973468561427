import { FetchApplicationsRoutine} from '../redux-routines';

export const initialState = {
  data: {},
  isFetching: false,
  error: null,
};

export const applicationsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FetchApplicationsRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case FetchApplicationsRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchApplicationsRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
