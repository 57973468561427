import translationEN from '~translation/langs/en/translation.json';
import translationRU from '~translation/langs/ru/translation.json';
import translationPT from '~translation/langs/pt/translation.json';

export const languageList = [
  {
    label: 'English',
    value: 'en',
    translation: translationEN,
    isoCode: 'EN',
  },
  {
    label: 'Português',
    value: 'pt',
    translation: translationPT,
    isoCode: 'PT',
  },
];