import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { AuthenticationSecure } from '@oliasoft/pkce';
import { NAVIGATION_CONSTANTS } from '../../constants';
import * as routes from '../../routes';

const MainRoutesContainer = () => {
  return (
    <div>
      <Switch>
        <AuthenticationSecure>
          <Route
            path={NAVIGATION_CONSTANTS.ROOT}
            component={routes.DashboardScreen}
          />
        </AuthenticationSecure>
      </Switch>
    </div>
  );
};

MainRoutesContainer.propTypes = {};

export default MainRoutesContainer;
